import React, {
  Fragment,
  useEffect,
  useState,
  useCallback,
} from 'react'

import { 
  Page,
  LegacyTabs,
  Frame,
  Loading,
  Layout,
  Card,
} from "@shopify/polaris"
import DashboardPage from './dashboard'
import { withFirebase } from '../../providers/firebase'
import getTodayAnalytics from '../../helpers/getTodayAnalytics'
import getAnalytics from '../../helpers/getAnalytics'
import getRules from '../../helpers/getRules'
import Constants from '../../helpers/constants'
import "./global.css"


function Index(props) {

  const { firebase, token, shop, host, user_id, initialSelectedTabIndex = Constants.TAB_INDEX.ANALYTICS, location = {} } = props
  const locationState = location.state || {}
  const [selectedTabIndex, setSelectedTabIndex] = useState(locationState.tabIndex || initialSelectedTabIndex)

  const [rules, setRules] = useState(locationState.rules || [])
  const [analytics, setAnalytics] = useState(locationState.analytics || null)
  const [refreshLoading, setRefreshLoading] = useState(false)
  const [refreshCount, setRefreshCount] = useState(0)
  const [analyticsToday, setAnalyticsToday] = useState(null)
  const [getAllAnalytics, setGetAllAnalytics] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const today = new Date(new Date().setHours(0,0,0,0));
  const [chartDateRange , setChartDateRange] = useState(locationState.chartDateRange || {startDate: new Date(new Date().setDate(today.getDate() - 30)), endDate: today})
  
  
  useEffect(() => {
    // useEffect cannot directly be async
    const run = async () => {
      const rulesResult = await getRules(token, shop, host)
      setRules(rulesResult.data.rules)
      setLoaded(true)
    }
    run()
  }, [token, shop, selectedTabIndex]);

  useEffect(() => {
    const run = async () => {
      const analyticsResult = await getAnalytics(token, shop, host, chartDateRange.startDate, chartDateRange.endDate)
      setAnalytics(analyticsResult.data.data)
      
      setRefreshCount(refreshCount+1)
      //calAnalyticsPerOffer(analyticsResult.data.data)
    }
    run()
  }, [token, shop, chartDateRange]);

  useEffect(() => {
    const run = async () => {
      try{
        const analyticsTodayResult = await getTodayAnalytics(token, shop, host)
        setAnalyticsToday(analyticsTodayResult.data.result)
      }catch(e){
        console.log(e)
      }
    }
    if(refreshCount && (!analyticsToday || chartDateRange.endDate.getTime() >= today.getTime() || ( Array.isArray(analyticsToday) && analyticsToday.length === 1 && analyticsToday[0].dateString && chartDateRange.endDate.getTime() >= new Date(analyticsToday[0].dateString).setHours(0,0,0,0)))){
      run()
    }
  }, [refreshCount]);

  useEffect(() => {
    if(Array.isArray(analyticsToday) && analyticsToday.length === 1 && analytics && (chartDateRange.endDate.getTime() >= today.getTime() || (analyticsToday[0].dateString && chartDateRange.endDate.getTime() >= new Date(analyticsToday[0].dateString).setHours(0,0,0,0)))){
      let allAnalytics
      const index = analytics.findIndex(item => item.dateString === analyticsToday[0].dateString)
      if (index !== -1) {
        allAnalytics = [...analytics]
        allAnalytics[index] = analyticsToday[0];
      } else {
        allAnalytics = analytics.concat(analyticsToday[0])
      }
      let totalImpressions = 1
      if(refreshCount == 1){
        totalImpressions = allAnalytics.reduce((memo, item) => {
          return memo + Number(item?.impressions?.totalCount)
        }, 0)
      }
      setAnalytics(allAnalytics)
      if(totalImpressions === 0 && !getAllAnalytics && refreshCount == 1){
        setGetAllAnalytics(true)
      }
    }
  }, [analyticsToday, refreshCount])
  
  useEffect(() => {
    const run = async () => {
      try{
        const analyticsResult = await getAnalytics(token, shop, host, chartDateRange.startDate, chartDateRange.endDate, getAllAnalytics)
        setAnalytics(analyticsResult.data.data)
        setRefreshCount(refreshCount+1)
      }catch(e){
        console.log(e)
      }
    }
    if(getAllAnalytics){
      run()
    }
  }, [getAllAnalytics])

  const refresh = async() => {
    if(refreshLoading){
      return
    }
    setRefreshLoading(true)
    const analyticsResult = await getAnalytics(token, shop, host, chartDateRange.startDate, chartDateRange.endDate, getAllAnalytics)
    setAnalytics(analyticsResult.data.data)
    setRefreshCount(refreshCount+1)
    setRefreshLoading(false)
  }
  if (!loaded) {
    return (
      <Frame>
      <Loading />
      <Page fullWidth title="Loading...">
        <Layout>
          <Layout.Section>
            <Card>
                <p>{`Loading shop ${shop}...`}</p>
            </Card>
          </Layout.Section>
        </Layout>
      </Page>
    </Frame>
    );
  }
  else {
    return (
          <Page
            title="Dashboard"
            fullWidth
          >
            <DashboardPage 
              rawAnalytics={analytics}
              rules={rules}
              key={analytics && analytics.length > 0 ? analytics[0].dateString+analytics[analytics.length-1].dateString + refreshCount : refreshCount}
              setChartDateRange={setChartDateRange}
              chartDateRange={chartDateRange}
              refreshLoading={refreshLoading}
              refresh={refresh}
              setSelectedTabIndex={setSelectedTabIndex}
              {...props}
            />
          </Page>
    );
  }
}

export default withFirebase(Index);